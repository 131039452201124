<template>
    <v-container fluid>
        <v-card flat :loading = loading>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card-text>
                    <v-row class="flex-nowrap">
                        <v-col cols="1" style="max-width: 80px">
                            <v-btn :to="{name: 'dashboard'}" class="back-btn" link large>
                                <v-icon>mdi-less-than</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="11">
                            <v-subheader class="headline">{{ $t('qr_payment') }}</v-subheader>
                        </v-col>
                    </v-row>

                    <v-row justify="center">

                        <v-col cols="12" md="3"  class="max-column">
                            <ValidationProvider ref="amount" rules="required|amount_symbol"
                                                v-slot="{ errors, valid }">
                                <v-text-field
                                    v-model="amount" type="text"
                                    class="centered-input font-size-30"
                                    readonly
                                    dense
                                    height="50px"
                                ></v-text-field>
                            </ValidationProvider>
                            <v-btn v-for="(number, index) in numbers"
                                   :key="index"
                                    elevation="2"
                                    text
                                    depressed
                                    height="50px"
                                    width="80px"
                                    class="my-2 mx-2 font-size-22"
                                    @click="keyPress(number)"
                            >{{ number }}</v-btn>
                            <v-btn
                                elevation="2"
                                text
                                depressed
                                height="50px"
                                width="80px"
                                class="my-2 mx-2 font-size-22 float-right mr-5"
                                @click="keyPressClear()"
                            >
                                <v-icon>
                                    mdi-backspace-outline
                                </v-icon>
                            </v-btn>
                            <v-btn
                                   elevation="2"
                                   text
                                   depressed
                                   height="50px"
                                   width="80px"
                                   class="my-2 mx-2 font-size-22  float-right"
                                   @click="keyPress(0)"
                            >0</v-btn>

                        </v-col>

                    </v-row>
                    <v-row justify="center">
                        <v-col cols="12" md="3" class="max-column-bottom">
                            <v-btn
                                color="primary"
                                block
                                elevation="2"
                                height="50px"
                                :disabled="invalid || loading"
                                :loading="loading"
                                @click="totalPriceAmount(amount)"
                            >{{ $t('for_payment') }}</v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>
            </ValidationObserver>
        </v-card>


        <v-dialog
            v-model="dialogQrCode"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >


            <v-card>
                <v-toolbar
                    elevation="0"
                >
                    <v-btn
                        icon

                        @click="cancelDialogQrCode()"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-row justify="center" class="mb-4 mt-16">
                    <span class="text-h3">{{totalPrice}}</span>
                </v-row>

                <v-row v-if="transaction_status_id === 0" justify="center">
                    <div>{{ $t('scan_and_pay') }}</div>
                </v-row>

                <v-row v-if="transaction_status_id === 0" justify="center" class="my-10">
                    <qr-code
                        :text="JSON.stringify(params)"
                    >
                    </qr-code>
                </v-row>

                <v-row v-if="transaction_status_id === 1" justify="center" class="my-10">
                    <v-progress-circular
                        indeterminate
                        :width="10"
                        size="128"
                    ></v-progress-circular>
                </v-row>
                <v-row v-if="transaction_status_id === 1" justify="center" class="my-10">
                    <v-col sm="12" class="text-center text-h5">Ожидает потверждения оплаты от клиета</v-col>
                </v-row>

                <v-row v-if="transaction_status_id === 2" justify="center" class="my-10">
                    <v-col sm="12" class="text-center text-h5">Оплачено</v-col>
                </v-row>

                <v-row v-if="transaction_status_id === 3" justify="center" class="my-10">
                    <v-col sm="12" class="text-center text-h5">Ошибка оплаты</v-col>
                </v-row>


            </v-card>
        </v-dialog>
   </v-container>


</template>
<script>


import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        name: 'Notifications',
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                loading: false,
                qr_uuid: null,
                numbers: [1,2,3,4,5,6,7,8,9],
                amount: "0 ₸",
                symbol: " ₸",
                dialogQrCode: false,
                totalPrice: "",
                transaction_status_id: 0,
                params:{},
            }
        },
        async mounted() {
            await this.listenChannelTransactionWebsocket()
        },
        methods: {
            listenChannelTransactionWebsocket() {
                //console.log('ddddd' + `App.Models.Partner.${this.$auth.user().ulid}`)
                window.Echo
                    .private(`App.Models.Partner.${this.$auth.user().ulid}`)
                    .listen(`.Event.Transaction`, (event) => {
                        if(event.transaction_status_id && parseInt(event.total) === parseInt(this.amount.slice(0, -2))){
                            if(event.transaction_status_id===1){
                                this.transaction_status_id = event.transaction_status_id;
                                this.qr_uuid = event.ulid;
                            }
                            else if((event.transaction_status_id===2 || event.transaction_status_id===3) && this.qr_uuid === event.ulid){
                                this.transaction_status_id = event.transaction_status_id;
                            }
                        }
                    });
            },
            async keyPress(number){
                if(this.amount.length < 10){
                    if( this.amount === "0 ₸"){
                        if(number > 0){
                            this.amount =  number.toString() + this.symbol;
                        }
                    }
                    else {
                        this.amount = this.amount.slice(0, -2);
                        this.amount = this.amount.toString() + number.toString() + this.symbol;
                    }
                }

            },
            async keyPressClear(){
                if(this.amount !== "0 ₸"){
                    this.amount = this.amount.slice(0, -3);
                    if(this.amount === ""){
                        this.amount = "0";
                    }
                    this.amount = this.amount + this.symbol;
                }
            },
            async totalPriceAmount(amount){
                let price = amount.slice(0, -2);
                price = (parseInt(price)).toLocaleString("ru-RU", {style: "currency", currency: "KZT", minimumFractionDigits: 0})
                price = price.toString().slice(0, -3);
                this.totalPrice =  price.toString() + this.symbol;
                this.dialogQrCode = true
                this.params = {
                    "total": amount.slice(0, -2),
                    "partner_uuid": this.$auth.user().ulid
                }
            },
            cancelDialogQrCode(){
                this.dialogQrCode = false;
                this.transaction_status_id = 0;
                this.param = {};
                this.amount = "0 ₸";
                this.qr_uuid = null;
            }
        }
    }
</script>
<style scoped>
.centered-input >>> input {
    text-align: center
}
.centered-input >>> .v-input__slot::before {
    border-style: none !important;
}
.v-text-field > .v-input__control > .centered-input:before{
    border-style: none !important;
}
.max-column{
    width: 325px !important;
    min-width:325px !important;
    max-width:325px !important;
}
.max-column-bottom{
    width: 300px !important;
    min-width:300px !important;
    max-width:300px !important;
}
</style>

